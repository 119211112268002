<template>
  <v-app id="inspire" style="width: 100%">
    <!--  Create new group dialog-->
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-dialog v-model="createGroupDialog" width="500" :persistent="true">
      <v-card>
        <v-card-title>
          <span class="headline">Create new group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" >
                <label>Group Name</label>
                <v-text-field  dense placeholder="Group name" name="Group Name" outlined  v-validate="'required'" v-model="groupName"></v-text-field>
                <span class="text-danger text-sm" v-show="errors.has('Group Name')">{{ errors.first('Group Name') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseCreateGroupDialog">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="disable" @click="submitForm">
            {{btnText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  Create new role dialog-->
    <v-dialog v-model="createRoleDialog" width="500" >
      <v-card>
        <v-card-title>
          <span class="headline">Create new role</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" >
                <v-select :items="AllFeatures" label="Features" name="Features" v-validate="'required'" return-object
                  v-model="selectedFeature"></v-select>
                <span class="text-danger text-sm"
                  v-show="errors.has('Features')">{{ errors.first('Features') }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6" md="4">
                <v-checkbox v-model="privileges" label="READ" value="R"></v-checkbox>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6" md="4">
                <v-checkbox v-model="privileges" label="ADD" value="A"></v-checkbox>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6" md="4">
                <v-checkbox v-model="privileges" label="EDIT" value="E"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseCreateRoleDialog">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitRoles" :disabled="disable">
            {{btnText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row style="background-color: #eeeeee">
      <v-col cols="12" sm="12" md="5">
        <v-card>
          <v-card-text>
            <div style="padding-bottom: 30px">
              <strong style="font-size: 20px">Groups</strong>
              <strong class="float-lg-right">
                <v-btn lass="ma-2" small dark @click="showCreateGroupDialog">
                  New group
                  <v-icon dark right>
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </strong>
            </div>
            <div v-for="(group,y) in groups" :key="y" style="font-family:'Montserrat', Helvetica, Arial, sans-serif !important;">
              <div class="groupDiv" @click="setActiveGroup(y,group.value)" :id="'group'+y">
                <strong>{{group.text}}</strong>
                <span class="float-lg-right">
                  <v-icon aria-hidden="false" style="padding-top: 15px;color: #ea4c5a;z-index: 9" @click="deleteGroup(group)">
                    delete
                  </v-icon>
                </span>
                <div style="padding-top: 5px">
                  <v-badge color="black" :content="Number(y+1)"></v-badge>
                </div>
              </div>
              <div style="height: 8px"></div>
            </div>
            <div style="text-align: center;cursor: pointer;color: #ea4c5a"><strong>___</strong></div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- right div for active group-->
      <v-col cols="12" sm="12" md="7" style="padding-left: 0px">
        <v-card v-if="clicked" style="height:100vh;">
          <v-card-text>
            <center><img :src="rolesImage" style="position:relative;top:250px;width: 300px;padding: 50px"></center>
          </v-card-text>
        </v-card>
        <v-card v-if="clicked===false">
          <v-card-text>
            <div class="activeGroupDiv">
              <strong style="font-size: 20px">{{groups[activeIndex].text}}</strong>
              <span class="float-lg-right">
                <v-btn lass="ma-2" small dark @click="showCreateRoleDialog">
                  New role
                  <v-icon dark right>
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </span>
              <div style="padding-top: 5px">
                <strong style="font-size: 16px">Created on: </strong><span>{{groups[activeIndex].created_at}}</span>
              </div>
            </div>
            <br>
            <div>
              <v-data-table
                :headers="headers"
                :items="roles"
                :search="search"
              >
                <template v-slot:item.id="{item}">
                  <v-chip small :color="`#9c9c9c`" style="color: white;font-weight: 600;width: 25px;padding: 8px;">{{roles.map(function(x) {return x.id; }).indexOf(item.id)+1}}</v-chip>
                </template>
                <template v-slot:item.permissions="{ item }">
                  {{item.permissions.toString()}}
                </template>
                <template v-slot:item.options="{ item }">
                  <v-icon aria-hidden="false" style="padding-top: 15px;color: #ea4c5a;cursor: pointer" @click="deleteRole(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:header.privileges="{ item }">
                  <span style="justify-content: center">Privileges</span>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
function initialState () {
  return {
    createGroupDialog: false,
    privileges: [],
    search: '',
    headers: [
      {
        text: '#',
        align: 'start',
        filterable: false,
        value: 'id',
      },
      { text: 'Features', value: 'name' },
      { text: 'Permissions', value: 'permissions' },
      { text: '', value: 'options' },

    ],
    desserts: [
      {
        number: 1,
        feature: 'Follin',
        privileges: 6,
      },
      {
        number: 2,
        feature: 'Employees',
        privileges: 8,
      }],
    groupName: '',
    createRoleDialog: '',
    selectedFeature: '',
    features: [],
    disable: false,
    btnText: 'Save',
    activeIndex: '0',
    clicked: true,
    dbPrivileges: [],
    logger: [],
    header: '',
    groups: [],
    AllFeatures: [],
    groupId: '',
    roles: [],
  }
}
export default {
  name: 'GroupsSettings',
  data () {
    return initialState()
  },
  created () {
    this.fetchGroup()
    this.fetchFeatures()
  },
  computed: {
    rolesImage () {
      return require('@/assets/icons/1.jpg')
    },
  },
  methods: {
    async fetchGroup () {
      // this.progress = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_groups',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.groups = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          if (this.filter === 'class') {
            this.filtered = []
          } else if (this.filter === 'dept') {
            this.filtered = []
          } else {
            this.filtered = []
          }
        } else {
          console.log(error)
        }
      }
    },
    async fetchGroupRoles () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_groups_roles/' + this.groupId,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.roles = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
            this.roles = []
        }
      }
    },
    async fetchFeatures () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_features',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.AllFeatures = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.AllFeatures = []
        }
      }
    },
    deleteGroup (row) {
      var groups = this.$store.state.apps.groups
      this.$confirm(
        {
          clicksCount: 1,
          confirm: false,
          auth: false,
          title: '!',
          message: 'Are you sure You want to delete ' + row.text + ' ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: (confirm) => {
            if (confirm == true) {
              this.$store.dispatch('apps/deleteGroup', row.value)
                .then(res => {
                  this.getGroups()
                  this.responseMsg(1, res.data.message, res.data.success)
                })
                .catch(e => {
                  this.$vs.loading.close()
                  this.responseMsg(0, e.response.data.message, e.response.data.error)
                })
            }
          },
        })
    },
    deleteRole (row) {
      var roles = this.$store.state.apps.roles
      var index = roles.indexOf(row)
      this.$confirm(
        {
          clicksCount: 1,
          confirm: false,
          auth: false,
          title: '!',
          message: 'Are you sure You want to delete ' + row.feature + ' ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: (confirm) => {
            if (confirm == true) {
              this.$store.dispatch('apps/deleteRole', row.id)
                .then(res => {
                  roles.splice(index, 1)
                  this.responseMsg(1, res.data.message, res.data.success)
                })
                .catch(e => {
                  this.$vs.loading.close()
                  this.responseMsg(0, e.response.data.message, e.response.data.error)
                })
            }
          },
        })
    },
    getGroupRoles () {
      const groupId = this.$store.state.apps.groups[this.activeIndex].value
      this.$store.dispatch('apps/getGroupRoles', groupId).then(res => {
        this.responseMsg(1, res.data.message, res.data.success)
      }).catch(e => {
        if (e.response && e.response.status) {
          this.responseMsg(0, e.response.data.message, e.response.data.error)
        }
      })
    },
    getFeatures () {
      this.$store.dispatch('apps/getFeatures').then(res => {
        this.responseMsg(1, res.data.message, res.data.success)
      }).catch(e => {
        if (e.response && e.response.status) {
          this.responseMsg(0, e.response.data.message, e.response.data.error)
        }
      })
    },
    resetWindow: function () {
      Object.assign(this.$data, initialState())
    },
    async createGroup () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      // this.$vs.loading()
      const payload = {
        groupName: this.groupName,
      }
      this.disable = false
      this.btnText = 'Please wait...'
      await this.axios({
        url: 'manipulate_group',
        method: 'post',
        data: payload,
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      })
        .then(res => {
          // this.responseMsg(1, res.data.message, res.data.success)
          this.fetchGroup()
          this.resetWindow()
          this.CloseCreateGroupDialog()
          this.getGroups()
          this.disable = false
          this.btnText = 'Save'
        }).catch(e => {
          if (e.response && e.response.status) {
            this.responseMsg(0, e.response.data.message, e.response.data.error)
            this.disable = false
            this.btnText = 'Save'
          }
        })
    },
    getGroups () {
      this.$store.dispatch('apps/getGroups').then(res => {
        this.responseMsg(1, res.data.message, res.data.success)
      }).catch(e => {
        this.responseMsg(0, e.response.data.message, e.response.data.error)
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.createGroup()
        } else {
          // form have errors
        }
      })
    },
    submitRoles () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.createRoles()
        } else {
          // form have errors
        }
      })
    },
    async createRoles () {
      this.disable = false
      this.btnText = 'Please wait...'
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'manipulate_role',
          method: 'post',
          data: {
            groupId: this.groupId,
            selectedFeature: this.selectedFeature.value,
            privileges: this.privileges,
          },
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.CloseCreateRoleDialog()
          this.roles.push(res.data)
          this.disable = false
          this.btnText = 'Save'
          console.log(this.roles)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    setActiveGroup (index, groupId) {
      this.groupId = groupId
      this.clicked = false
      this.activeIndex = index
      var n = document.getElementsByClassName('groupDiv')
      for (var i = 0; i < n.length; i++) {
        console.log(n[i].classList.remove('active'))
      }
      var x = document.getElementById('group' + index)
      x.classList.add('active')
      this.fetchGroupRoles()
    },
    CloseCreateGroupDialog () {
      this.createGroupDialog = false
    },
    showCreateGroupDialog () {
      this.createGroupDialog = true
    },
    CloseCreateRoleDialog () {
      this.createRoleDialog = false
    },
    showCreateRoleDialog () {
      this.createRoleDialog = true
    },
  },
}
</script>

<style scoped>
  .groupDiv {
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
    cursor: pointer;
  }

  .groupDiv:hover {
    border: solid 1px black;
  }

  .activeGroupDiv {
    cursor: pointer;
    border-bottom: solid 1px black;
    background-color: #f8f8f8;
    padding: 10px;
  }
  .active{
    border: solid 2px black;
  }
</style>
